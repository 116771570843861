import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/ephys/Development/reworkjs/core/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "customizing-the-framework"
    }}>{`Customizing the framework`}</h1>
    <h2 {...{
      "id": "hook-system"
    }}>{`Hook System`}</h2>
    <p>{`Hooks allow you to change the behavior of parts of the framework.`}</p>
    <p>{`In order to enable hooks, you will want to provide the path to your hook files inside of your `}<a parentName="p" {...{
        "href": "configuration.md#hooks"
      }}>{`configuration file`}</a>{`.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`hooks.client`}</inlineCode>{` will accept the hook the framework will run in the browser version of the application`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`hooks.server`}</inlineCode>{` will accept the hook the framework will run during server-side rendering`}</li>
    </ul>
    <p><em parentName="p">{`Example:`}</em></p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "hooks": {
    "client": "./src/hooks/client",
    "server": "./src/hooks/server"
  }
}
`}</code></pre>
    <p>{`Paths are resolved relative to the configuration file.`}</p>
    <p>{`Your hook files must respect two key points:`}</p>
    <ul>
      <li parentName="ul">{`They must have a default export`}</li>
      <li parentName="ul">{`The default export must be a class definition`}</li>
    </ul>
    <p>{`Using a hook is as simple as providing the correctly named method inside of your class definition.`}</p>
    <p><em parentName="p">{`Example`}</em>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { Provider } from 'react-redux';
import createConfiguredStore from '../create-store';

export default class ClientSideHook {

  constructor() {
    this.store = createConfiguredStore();
  }

  wrapRootComponent(component) {

    return (
      // add a redux store Provider
      <Provider store={this.store}>
        {component}
      </Provider>
    );
  }
}
`}</code></pre>
    <p>{`Which hooks you can use depend on whether you are server-side or client-side`}</p>
    <h3 {...{
      "id": "common-hooks"
    }}>{`Common hooks`}</h3>
    <p>{`These are hooks that are available on both client & server sides.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`wrapRootComponent(React.AbstractComponent): React.AbstractComponent`}</inlineCode>{`: use this hook to wrap the root component
of the React tree. Useful if you need to add providers.`}</li>
    </ul>
    <h3 {...{
      "id": "client-side-hooks"
    }}>{`Client-side hooks`}</h3>
    <p><strong parentName="p">{`Note`}</strong>{`: A single instance of your hook will be created.`}</p>
    <p>{`Currently the framework does not offer any client-side specific hook. `}<a parentName="p" {...{
        "href": "#common-hooks"
      }}>{`Common hooks`}</a>{` are however available`}</p>
    <h3 {...{
      "id": "server-side-hooks-ssr-only"
    }}>{`Server-side hooks (SSR only)`}</h3>
    <p><strong parentName="p">{`Note`}</strong>{`: A new instance of your hook is created for each request, you can therefore store state specific to one request.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`preRender(HtmlParts): HtmlParts`}</inlineCode>{`: this hook is called after React is done rendering
and right before the page wrapper itself is being rendered. (If you have a better name for this, please open an issue!).`}</p>
        <p parentName="li">{`The point of this hook is `}<em parentName="p">{`not`}</em>{` to change how the page is rendered,
but to add extra content to the page (scripts, meta tags, etc).`}</p>
        <p parentName="li">{`It receives and should return the following format:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-typescript"
          }}>{`interface Htmlparts {
  /** what will be put inside of <head>. Eg. Webpack preload scripts & CSS */
  header: string;

  /** The HTML outputted by React, the contents of <div id=app /> */
  body: string;

  /** What will be placed at the very end of the page */
  footer: string;

  /** The output of Helmet.renderStatic. See https://github.com/nfl/react-helmet#server-usage */
  helmet: Object;
}
`}</code></pre>
        <p parentName="li"><em parentName="p">{`note`}</em>{`: This API is highly prone to changes to unify with Helmet.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`postRequest(): void`}</inlineCode>{`: This method is called after the server is done responding to the client with the generated page.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`static configureServerApp(app: ExpressInstance): void`}</inlineCode>{`: This method is called before the server is started. It enables you to
add new endpoints to the express instance.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "plugin-system"
    }}>{`Plugin system`}</h2>
    <p>{`While Hooks are great for solving needs specific to your project, plugins enable you
to provide generic solutions that are reusable across projects.`}</p>
    <p>{`Plugins are a layer on top of hook.`}</p>
    <h3 {...{
      "id": "enabling-a-plugin"
    }}>{`Enabling a plugin`}</h3>
    <p>{`In order to enable a plugin, you need to specify the name of the plugin as a key in the `}<inlineCode parentName="p">{`plugins`}</inlineCode>{` object of
your `}<a parentName="p" {...{
        "href": "configuration.md#plugins"
      }}>{`configuration file`}</a>{`. The value of that entry is the configuration of the plugin.`}</p>
    <p><em parentName="p">{`Example:`}</em></p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "plugins": {
    "@reworkjs/redux": {
      "global-stores": "./app/stores"
    }
  }
}
`}</code></pre>
    <p>{`The above example will cause the framework to load the `}<inlineCode parentName="p">{`@reworkjs/redux/plugin.js`}</inlineCode>{` plugin definition.`}</p>
    <h3 {...{
      "id": "plugin-definition-resolution"
    }}>{`Plugin definition resolution`}</h3>
    <p>{`If the path to the plugin definition points to a folder, the framework will attempt to resolve `}<inlineCode parentName="p">{`plugin.js`}</inlineCode>{` in that folder.`}</p>
    <p>{`Alternatively, you can specify a file directly: `}<inlineCode parentName="p">{`./plugins/my-custom-plugin.js`}</inlineCode>{`.`}</p>
    <p>{`Paths are resolved relative to the configuration file.`}</p>
    <h3 {...{
      "id": "creating-a-plugin-definition"
    }}>{`Creating a plugin definition`}</h3>
    <p>{`A typical plugin definition looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`'use strict';

const path = require('path');

module.exports = class ReduxPlugin {

  constructor(params) {
    const config = params.pluginConfig;
    const configDir = path.dirname(params.configFile);

    this.globalStoresDir = config['global-stores'] ? path.resolve(configDir, config['global-stores']) : null;
  }

  getHooks() {

    return {
      client: path.resolve(\`\${__dirname}/../hook-client\`),
      server: path.resolve(\`\${__dirname}/../hook-server\`),
    };
  }
}
`}</code></pre>
    <p>{`Some key points:`}</p>
    <ul>
      <li parentName="ul">{`Your file will not be automatically transpiled by the framework, it must be compatible with your Node version.`}</li>
      <li parentName="ul">{`The main/default export of your plugin must be a class`}</li>
    </ul>
    <p><strong parentName="p">{`Methods`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`constructor`}</inlineCode>{`: Your plugin will be constructed and will be passed the following parameters:`}</p>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`params.configFile`}</inlineCode>{`: The location of the configFile in which your plugin was declared`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`params.pluginConfig`}</inlineCode>{`: The configuration of your plugin as specified in `}<inlineCode parentName="li">{`configFile`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`getHooks`}</inlineCode>{`: This method allows your to provide the different hooks the framework
will include in the project, the format is the same as the one described in `}<a parentName="p" {...{
            "href": "#hook-system"
          }}>{`Hook System`}</a>{`.`}</p>
        <p parentName="li">{`A notable difference is that the path to the hook file must be absolute.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      